<!--
  PACKAGE_NAME : src/pages/euc/device/hunt
  FILE_NAME : /modal-set-linedn.vue
  AUTHOR : jhsim
  DATE : 2024-05-01
  DESCRIPTION : 헌트관리 - 회선번호 설정 모달
-->
<template>
  <div>
    <table class="table_form line-bin fl" style="width: 100%">
      <colgroup>
        <col style="width: 20%" />
        <col style="width: 80%" />
      </colgroup>
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
        <tr>
          <th>{{ this.$_msgContents('UC.WORD.LINEGROUP') }}</th>
          <td>{{ this.rowInfo.linegroup.name }}</td>
        </tr>
      </thead>
    </table>
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div style="padding-top: 50px">
        <Search
          codeKey="search_type_line"
          :searchObj="searchType.obj"
          :useKeywordType="true"
          @change-search-type="$_changeSearchType"
          @onSearchClick="$_searchData($event, 'search_type_line')"
        >
        </Search>
      </div>
    </div>
    <table class="table_form line-bin">
      <tbody>
        <tr>
          <esp-dx-data-grid
            :data-grid="lineGrid"
            ref="lineGrid"
            :auto-width="true"
            @selection-changed="onSelectionChanged($event, 'line')"
          ></esp-dx-data-grid>
        </tr>
        <tr>
          <esp-dx-data-grid
            :data-grid="lineGroupDnGrid"
            ref="lineGroupDnGrid"
            :auto-width="true"
            @selection-changed="onSelectionChanged($event, 'lineGroupDn')"
          ></esp-dx-data-grid>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxLookup } from 'devextreme-vue/data-grid';
  import { DxColumn } from 'devextreme-vue/tree-list';
  import { isSuccess } from '@/plugins/common-lib';
  import DxButton from 'devextreme-vue/button';
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      DxPopup,
      DxToolbarItem,
      DxColumn,
      DxButton,
      DxLookup,
      Search,
      DxSelectBox,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },

      rowInfo: {
        type: Object,
      },
      isOpened: {
        type: Boolean,
      },
      cmList: {
        type: Array,
      },
      pickupGroupList: {
        type: Array,
      },
      lineGroupId: {
        type: Number,
      },
    },
    watch: {
      async isOpened() {
        if (this.isOpened) {
          await this.selectDataList();
          await this.selectLineList();
        } else {
          this.refreshgGrid();
        }
      },
    },
    data() {
      return {
        // pickArr: [],
        lineGrid: {
          refName: 'lineGrid',
          allowColumnResizing: false, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: false, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '300', // 주석처리시 100%
          // height: 'calc(100vh - 600px)', // 주석처리시 100%
          apiActionNm: {
            update: '',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            rowUpdating: true,
            cellPrepared: true,
            saving: true,
            // initNewRow: true,
            selectionChanged: true,
            // toolbarPreparing: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.cmNm) {
                  let cmNm = '';
                  this.cmList.forEach(item => {
                    if (rowData.cmNm == item.cmNm) {
                      cmNm = item.description;
                    }
                  });
                  return cmNm;
                }
              },
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'pattern',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
            },
          ],
        },
        lineGroupDnGrid: {
          refName: 'lineGroupDnGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: false, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          //   height: '300', // 주석처리시 100%
          height: 'calc(100vh - 600px)', // 주석처리시 100%
          apiActionNm: {
            update: '',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            rowUpdating: true,
            cellPrepared: true,
            saving: true,
            initNewRow: true,
            selectionChanged: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: false,
            pageSize: 200,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'row', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.cmNm) {
                  let cmNm = '';
                  this.cmList.forEach(item => {
                    if (rowData.cmNm == item.cmNm) {
                      cmNm = item.description;
                    }
                  });
                  return cmNm;
                }
              },
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'pattern',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
            },
          ],
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          searchTexts: {
            value: this.pickupKeyword,
          },
          customTypes: {
            cm: null,
            model: null,
            status: null,
          },
          paramsData: null,
        },
      };
    },
    computed: {
      getCmList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        const result = this.$_getCode('search_type_device_cm').filter(d => d.delFl === 'N');
        const svrInx = params?.['cm'] ? params['cm'] : result[0].codeValue;
        this.setCustomTypes('cm', svrInx);
        return result;
      },
    },
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.lineGrid.showActionButtons.customButtons = this.getCustomButtons('line');
        this.lineGroupDnGrid.showActionButtons.customButtons = this.getCustomButtons('dn');
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {},
      /**@description : 커스텀 버튼 생성 이벤트 */
      getCustomButtons(type) {
        if (type == 'line') {
          const buttons = [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_msgContents('COMPONENTS.ADD'),
                elementAttr: { class: 'btn_XS default filled ' },
                width: 60,
                height: 30,
                onClick: () => {
                  this.addGridLine();
                },
              },
              location: 'before',
            },
          ];
          return buttons;
        } else if (type == 'dn') {
          const buttons = [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_msgContents('COMPONENTS.DELETE'),
                elementAttr: { class: 'btn_XS white light_filled trash fl' },
                width: 60,
                height: 30,
                onClick: () => {
                  this.deleteGridLine();
                },
              },
              location: 'before',
            },
          ];

          buttons.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('UC.WORD.APPLY'),
              elementAttr: { class: 'btn_XS default filled fr' },
              width: 60,
              height: 30,
              onClick: () => {
                this.onUpdateLineGroupDn();
              },
            },
            location: 'after',
          });
          return buttons;
        }
      },
      /**
       * @description 그리드 신규 row 추가 시 이벤트
       */
      onInitNewRow(e) {
        //교환기 정보 하나만 있을 경우 값 픽스
        if (this.cmList.length === 1) {
          e.data.cmNm = this.cmList[0].cmNm;
        }
      },
      onSelectionChanged(event, type) {},
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /**
       * @description 그리드 데이터 초기화
       */
      refreshgGrid() {
        this.lineGrid.dataSource = [];
        this.lineGroupDnGrid.dataSource = [];
      },
      async selectDataList(sort = 'regDt,id') {
        this.lineGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }
            params = {
              ...params,
              ...this.searchType.paramsData,
              ...this.searchType.customTypes,
              // loginId: this.$store.getters.getLoginId,
              // pagesize: 10,
            };

            const payload = {
              actionname: 'EUC_LINE_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      async selectLineList(sort = 'regDt,id') {
        this.lineGroupDnGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }
            params = { ...params, groupId: this.lineGroupId };

            const payload = {
              actionname: 'EUC_LINEGROUPDN_LINE_LIST',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description	부모컴포넌트에서 선택한 회선번호 정보 그리드에 셋팅하는 함수
       *  @param {}
       */
      async setLineGroupDnGrid(array) {
        if (!array) {
          return false;
        }

        this.$set(this.lineGroupDnGrid, 'dataSource', array);
      },
      /** @description	내선번호 선택하여 하단 그리드로 할당하는 함수
       *  @param {}
       */
      async addGridLine() {
        const selectRows = this.$refs.lineGrid.getGridInstance.getSelectedRowsData();
        const gridData = this.$refs.lineGroupDnGrid.getGridInstance.getDataSource()._items;

        if (selectRows?.length == 0) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        await selectRows.forEach(item => {
          const existingRow = gridData.find(
            row => row.routepartitionname == item.routepartitionname && row.pattern == item.pattern,
          );
          if (existingRow) {
            Object.assign(existingRow, item);
          } else {
            gridData.push(item);
          }
        });

        await gridData.sort((a, b) => {
          if (parseInt(a.id) > parseInt(b.id)) return 1;
          if (parseInt(a.id) === parseInt(b.id)) return 0;
          if (parseInt(a.id) < parseInt(b.id)) return -1;
        });
        await this.setLineGroupDnGrid(gridData);
        await this.$refs.lineGrid.clearSelection();
      },
      async deleteGridLine() {
        const selectRows = await this.$refs.lineGroupDnGrid.getGridInstance.getSelectedRowsData();
        // const selectRowKeys = await this.$refs.lineGroupDnGrid.getGridInstance.getSelectedRowKeys();

        if (selectRows?.length == 0) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        // let gridData = this.$refs.lineGroupDnGrid.getItems;
        const gridData = this.$refs.lineGroupDnGrid.getGridInstance.getDataSource()._items;
        if (selectRows.length === gridData.length) {
          this.setLineGroupDnGrid([]);
          return;
        }
        // await gridData.forEach((item, index) => {
        // 	selectRows.forEach((data, idx) => {
        // 		if (data.id == item.id && data.routepartitionname == item.routepartitionname && data.pattern == item.pattern) {
        // 			gridData.splice(index, 1);
        // 		}
        // 	});
        // });
        let data = gridData.filter(aItem => !selectRows.some(bItem => bItem.id === aItem.id));
        this.lineGroupDnGrid.dataSource = data;
        await this.$refs.lineGrid.clearSelection();
        await this.$refs.lineGroupDnGrid.clearSelection();
      },
      /** @description	갱신
       *  @param {type}	그리드 타입
       */
      async onUpdateLineGroupDn() {
        const selectRows = this.$refs.lineGroupDnGrid.getGridInstance.getSelectedRowsData();
        let linegroupName = '';

        linegroupName = this.rowInfo.linegroup.name;

        let flag = true;
        let prevCm = '';
        for (let i = 0; i < selectRows.length; i++) {
          if (i === 0) {
            prevCm = selectRows[i].cmNm;
            continue;
          }
          if (prevCm !== selectRows[i].cmNm) {
            flag = false;
          }
          prevCm = selectRows[i].cmNm;
        }

        if (!flag) {
          this.$_Msg(this.$_msgContents('UC.MESSAGE.CHECK_EXCHANGE_TYPE'));
          return;
        }
        if (selectRows.length === 0) {
          this.$_Msg();
        }

        const parseData = [];
        selectRows.forEach((item, index) => {
          parseData.push({ PATTERN: item.pattern, ROUTEPARTITION: item.routepartitionname, ORDER: index + 1 });
        });

        const params = {
          cmNm: prevCm == '' ? this.rowInfo.pilot.cmNm : prevCm,
          type: 'LINEGROUP',
          linegroup: linegroupName,
          linegroupDnList: JSON.stringify(parseData),
          loginId: this.$store.getters.getLoginId,
        };
        const payload = {
          actionname: 'EUC_LINEGROUPDN_UPDATE',
          data: params,
          loading: true,
        };
        let confirm =
          '선택한 항목 없이 저장할 경우 할당된 모든 내선이 회수됩니다.' +
          this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          this.$emit('closeModal', true);
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {},
  };
</script>
